import React from "react"

import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

import useSparkleAlert from "../../../alert/useSparkleAlert"

const CreateOrderConfirmationDialog = ({
  show = false,
  address,
  onConfirm = () => {},
  onDecline = () => {},
}) => {
  const { showSparkleAlert, SparkleAlert } = useSparkleAlert()

  const [addressChecked, setAddressChecked] = React.useState(false)

  const handleAddressChecked = (event) => {
    setAddressChecked(event.target.checked)
  }

  const handleConfirm = () => {
    if (addressChecked) {
      onConfirm()
    } else {
      showSparkleAlert("Please check the address", "error")
    }
  }

  return (
    <Dialog open={show} fullWidth>
      <DialogContent>
        <Stack spacing={2}>
          <Typography
            style={{
              textAlign: "center",
            }}
            variant="h5"
          >
            You are about to create an order
          </Typography>
          <Typography>Is this the correct address?</Typography>
          <Stack direction={"row"} spacing={2} alignItems="center">
            <Checkbox
              checked={addressChecked}
              onChange={handleAddressChecked}
            />
            <Typography>{address}</Typography>
          </Stack>
          <SparkleAlert />
          <Stack direction={"row"} spacing={2}>
            <Button variant="outlined" fullWidth onClick={onDecline}>
              Cancel
            </Button>
            <Button
              variant="contained"
              fullWidth
              onClick={handleConfirm}
              sx={{
                background: "#ffcf10",
              }}
            >
              Confirm
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default CreateOrderConfirmationDialog
