export const buttonStyle = {
  margin: "5px",
  background: "#ffcf10",
  border: 0,
  borderRadius: 10,
  color: "white",
  height: 60,
  padding: "0 30px",
  "&:hover": {
    backgroundColor: "#3d9afc",
  },
  "&:focus": {
    backgroundColor: "#3d9afc",
  },
}
