export const  generateReferenceNumber = () => {
    // Get the current timestamp in milliseconds
    const timestamp = Date.now().toString();
  
    // Generate a random 6-digit number
    const randomNumber = Math.floor(Math.random() * 1000000).toString().padStart(6, '0');
  
    // Combine the timestamp and random number to create the reference number
    const referenceNumber = timestamp + randomNumber;
  
    return referenceNumber;
  }