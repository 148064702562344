import React from "react"

import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import Button from '@mui/material/Button'
import Container from "@mui/material/Container"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { Form, Formik } from "formik"
import PhoneTextfield from "../components/Textfields/PhoneTextfield"
import { buttonStyle } from "../assets/mui-styles/button-style"
import useSparkleAlert from "../components/alert/useSparkleAlert"
import { sendPhoneOtp, verifyPhoneOtpAndUpdate, updatePhone } from "../api/public/auth"

// functions
const handlePhoneSubmit = async (data) => {
  try {
    return await updatePhone({ phone: data?.phone })
  } catch (error) {
    throw error
  }
}

const handlePINSubmit = async (data) => {
  try {
    await verifyPhoneOtpAndUpdate({
      phone: data?.phone,
      otp: data?.otp,
    })
  } catch (error) {
    throw error
  }
}

//FORM COMPONENTS

//Phone number form
export function PhoneNumberForm({
  setphone = () => { },
  setActiveForm = () => { },
  onSubmit = () => { },
  closeDialog = () => {}
}) {
  const { showSparkleAlert, SparkleAlert } = useSparkleAlert()

  const handleSubmit = async (data) => {
    try {
      await onSubmit(data)
      setphone(data?.phone)
      closeDialog()
    } catch (error) {
      showSparkleAlert(`${error}`, "error")
    }
  }

  return (
    <Formik initialValues={{}} onSubmit={handleSubmit}>
      {({ handleChange, isSubmitting }) => {
        return (
          <Form>
            <Stack spacing={2}>
              <Alert severity="info">
                <AlertTitle>Mobile Phone</AlertTitle>
                We need your mobile phone number to contact you
              </Alert>
              <PhoneTextfield
                data-testid={"MOBILE-NUMBER-FIELD"}
                name={"phone"}
                handleChange={handleChange}
              />
              <SparkleAlert />
              <Button
                data-testid={"CONFIRM-BUTTON"}
                type={"submit"}
                style={buttonStyle}
                fullWidth
              >
                {isSubmitting ? "LOADING..." : "CONFIRM"}
              </Button>
            </Stack>
          </Form>
        )
      }}
    </Formik>
  )
}

//PIN form
export function PINForm({
  phone,
  closeMobileVerificationDialog = () => { },
  onSubmit = () => { },
}) {
  const { showSparkleAlert, SparkleAlert } = useSparkleAlert()

  const [isSuccess, setIsSuccess] = React.useState(false)

  const handleSubmit = async (data) => {
    try {
      let fData = {
        phone: phone,
        otp: data?.otp,
      }

      await onSubmit(fData)
      showSparkleAlert("Mobile phone number verified", "success")

      setIsSuccess(true)
    } catch (error) {
      showSparkleAlert(`${error}`, "error")
    }
  }

  return (
    <Formik initialValues={{}} onSubmit={handleSubmit}>
      {({ handleChange, isSubmitting }) => {
        return (
          <Form>
            <Stack spacing={2}>
              <Alert severity="info">
                <AlertTitle>PIN</AlertTitle>
                We sent a PIN to your mobile number please type it here
              </Alert>
              {!isSuccess && (
                <TextField
                  required
                  name={"otp"}
                  variant="outlined"
                  placeholder={"Enter PIN here"}
                  onChange={handleChange}
                  inputProps={{
                    "data-testid": "PIN-FIELD",
                  }}
                />
              )}
              <SparkleAlert />

              {!isSuccess && (
                <Button
                  data-testid={"VERIFY-BUTTON"}
                  type={"submit"}
                  style={buttonStyle}
                  fullWidth
                >
                  {isSubmitting ? "LOADING..." : "VERIFY"}
                </Button>
              )}
              {isSuccess && (
                <Button
                  style={buttonStyle}
                  fullWidth
                  onClick={closeMobileVerificationDialog}
                >
                  Close
                </Button>
              )}
            </Stack>
          </Form>
        )
      }}
    </Formik>
  )
}

export default function useMobileNumberVerification() {
  const [show, setShow] = React.useState(false)

  const showMobileVerificationDialog = () => {
    setShow(true)
  }

  const closeMobileVerificationDialog = () => {
    setShow(false)
  }

  const MobileVerificationDialog = () => {
    const [activeForm, setActiveForm] = React.useState(0)

    const [phone, setphone] = React.useState(null)


    /*
    const ActiveForm = ({ index }) => {
      switch (index) {
        case 1:
          return (
            <PINForm
              phone={phone}
              closeMobileVerificationDialog={closeMobileVerificationDialog}
              onSubmit={(e) => handlePINSubmit(e)}
            />
          )
        default:
          return (
            <PhoneNumberForm
              setphone={(e) => setphone(e)}
              setActiveForm={(e) => setActiveForm(e)}
              onSubmit={(e) => handlePhoneSubmit(e)}
            />
          )
      }
    }*/

    return (
      <div>
        <Dialog open={show} onClose={closeMobileVerificationDialog} fullWidth>
          <Container
            style={{
              padding: 20,
            }}
          >
            <PhoneNumberForm
              setphone={(e) => setphone(e)}
              onSubmit={(e) => handlePhoneSubmit(e)}
              closeDialog = {closeMobileVerificationDialog}
            />
          </Container>
        </Dialog>
      </div>
    )
  }

  return { showMobileVerificationDialog, MobileVerificationDialog }
}
